import React from "react"
import ReCaptcha, { Loader } from "@pittica/gatsby-plugin-recaptcha"
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import WebriqForm from '../components/Form'


class Test extends React.Component {
	constructor(props, context) {
    super()
    Loader()
  }

  verifyCallback = (token) => {
  	console.log(token)
  }
	render () {
		return (
			<Container>
			<Row>
				<Col xs={10}>
					<WebriqForm
			        webriqform="true"
			        id="demo-form"
			        name="Newsletter"
			        className="newsletter-form"
			        data-form-id="5df32ff0254c2c2241d670f5"
			        data-thankyou-url="https://webriqgoesmad.webriq.com/pricing"
			      >
			        <Form.Group className="mb-0">
			          <Form.Control type="text" name="email" required placeholder="info@mail.com"/>
			          <Form.Label htmlFor="email">
			            Enter your email<span>*</span>
			          </Form.Label>
			        </Form.Group>
			        <small>Please verify your request*</small>
			        <Form.Group className="mt-3">
			          <ReCaptcha
					        action="test"
					        sitekey="6LeVbLUZAAAAAMY9p8n6mw5kYt4MDjeHZ8RjnbzW"
					        callback={this.verifyCallback}
					        badge='bottomleft'
					      />
			        </Form.Group>
			        <Form.Group className="mt-3">
			          <Button type="submit">Sign up</Button>
			        </Form.Group>
			      </WebriqForm>
				</Col>
			</Row>
      </Container>
    )
	}
}

const previewTeam = () => {
  return (
    <div><h2>Preview here</h2></div>
  )

}

export default Test